



































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: {
        
    }
})
export default class PatchNote extends Vue {
    textAlertPatchNote = `
    Hey Trooperz! The batch mode update has arrived! Click here to see what's new and changed in this update!
    `
    patchDialog = false

    patchNote = [
        {
            text: `
            <strong>It is now possible to send your Trooperz by batch via the "Your Trooperz" page!</strong><br>
            Click on "active batch", select the Trooperz to send, then click on "Set mode", select your new mode and go!
            Please note that batch mode is currently not optimized for mobiles and small screens. Improvements are coming for this type of device ;) .
            `
        },
        {
            text: `
            <strong>Energy is now displayed in "Live" mode.</strong><br>
            It means that the energy bar counts the energy to be collected (when the Trooperz is in rest) as well as the energy which will be spent (when the Trooperz is in mission mode or in training).
            `
        },
        {
            text: `
            <strong>Added T-bot filter.</strong><br>
            It is now possible to filter Trooperz with Tbot and without Tbot via the "Your Trooperz" page.
            `
        },
        {
            text: `
            <strong>Added filter stats.</strong><br>
            You can now display only Trooperz above certain stats (close combat, trickery, hacking, fire arms) in the "Your Trooperz" page.
            `
        },
        {
            text: `
            <strong>TCoin to harvest!</strong><br>
            You can now see all the Tcoin you have to harvest above your Trooperz in the "Your Trooperz" page.
            `
        },
        {
            text: `
            <strong>And of course..</strong> <br> a whole bunch of optimizations, bug fixes etc.
            `
        }
    ]

    openPatchDialog () {
        this.patchDialog = true
    }

    clickOk () {
        this.patchDialog = false
    }
}
