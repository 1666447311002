







import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: {}
})
export default class TbotImg extends Vue {
    @Prop({default: null})
    height!: string

    getStyle () {
        if (!this.height) {
            return null
        }
        return `height: ${this.height}`
    }
}
