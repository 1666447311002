




























































































































































































































































































































import { Component, Prop, Vue, PropSync, Watch } from 'vue-property-decorator';
import { TrooperzBoxParams, MissionMode, ModeInfo, MissionConfig, MissionData, WithKey, TrooperzAttributes, MutableAttributes, Family, JsonMission, RewardsType } from '@/type';
import {BatchParams, RewardsInfo, TrainingConfig, TrCallback} from '../../type'
import {setTrooperzModeBatch, setTrooperzModeBatchOneTransaction} from '../../contractCaller/contractCaller'
import ChooseMissionDialog from '../ChooseMissionDialog.vue'
import StatsBarFull from '../StatsBarFull.vue'
import RewardsIcon from '../RewardsIcon.vue'
import FamilyImg from '../Common/FamilyImg.vue'
import TbotImg from '../Common/TbotImg.vue'
import OperationLoadingDialog from '../OperationLoadingDialog.vue'
import { getMissionData } from '@/apiCaller/getMissionInfo';
import { convertValueFromContract, getInAttributes, getLvlWithXp, roundValue } from '../../utils/utilities'
import { getEnergyWithRewards, getRewardsColor, getRewardsForMode, getEnergyInfoWithRewards } from '../../utils/rewardsHelper'
import { getInforRewardsTcoin, getRoiByHours, convertByBlockToByHours, getFailedMissionRequirements, checkMissionRequirements } from '../../utils/missionHelper'
import { mixins } from 'vue-class-component';
import HasXp from '@/mixins/HasXp';
import BatchSelectAll from './BatchSelectAll.vue';
import BaseDialog from '../Common/BaseDialog.vue';
import { textMaxRewardsEnergy, textMaxRewardsOther } from '@/text/text';
import TcoinInfoBox from '../Common/TcoinInfoBox.vue'
import HasWarning from '@/mixins/HasWarning';
  
@Component({
    components: {
      ChooseMissionDialog,
      OperationLoadingDialog,
      StatsBarFull,
      FamilyImg,
      RewardsIcon,
      TbotImg,
      BatchSelectAll,
      BaseDialog,
      TcoinInfoBox
    }
})
export default class BatchCard extends mixins(HasXp, HasWarning) {
  @PropSync('boxParams', {required: true})
  boxParamsProp!: TrooperzBoxParams[]

  @PropSync('batchDialog', {required: true})
  batchDialogProp!: boolean

  @Prop({required: true})
  trooperzEnergy!: {[key: number]: number}

  @Prop({required: true})
  trooperzXp!: {[key: number]: number}

  @Prop({required: true})
  trooperzModes!: {[key: number]: ModeInfo}

  @Prop()
  currentBlock!: number

  @Prop()
  maxEnergy!: number

  @Prop()
  maxLvl!: number

  @Prop()
  rewardsInfos!: {[key: number]: RewardsInfo}

  @Prop()
  trainingConfig!: TrainingConfig | null

  @Prop({default: false})
  isMobile!: boolean

  search = ''

  missionList: Array<WithKey<MissionConfig>> = []
  missionData: Array<WithKey<MissionData>> = []

  selectedModes: {[key: number]: MissionMode | ''} = {}

  selectedMission: {[key: number]: number | null} = {}
  selectedMissionName: {[key: number]: string | null} = {}

  jsonMissionDictFromMissionDialog: Record<string, JsonMission> = {}

  modeOptions: {label: string, value: MissionMode}[] = [
    {
      label: 'Rest',
      value: 'rest'
    },
    {
      label: 'Train',
      value: 'train'
    },
    {
      label: 'Mission',
      value: 'mission'
    }
  ]
  

  dialogChooseMission = false
  currentTrooperzXpMissionDialog: null | number = null
  currentMissionIdMissionDialog: null | number = null
  currentTrooperzIdMissionDialog: null | number = null
  currentAttributesMissionDialog: null | TrooperzAttributes = null

  loadingMissionInfo = false

  opeHash?: string | null = null
  activeDialogTransaction = false
  loadingTransaction = false
  success = false

  dialogIsLoading = false


  dialogSetAll = false

  selectedModeAll: MissionMode | '' = '' 

  dialogChooseMissionAll = false

  contentSomeTrooperzNotUpdated = `
  Be careful, some Trooperz don't have their mode updated. Are you sure you want to include them in the batch?
  `

  contentSomeTrooperzFailRequirements = `
  Some Trooperz do not meet the requirements for this mission, and therefore their mode will not be updated.
  `

  getToHarvest (tokenId: number) {
    const currentMode = this.getCurrentMode(tokenId)
    if (!currentMode) {
      return null
    }
    let amount: number | string = this.rewardsInfos[tokenId].rewards
    if (amount) {
      const type = this.getRewardsTypeForTrooperzCurrentMode(tokenId)
      if (type === 'tcoin') {
        amount = amount.toFixed(8)
      }
      return `${amount} ${type}`
    }
  }

  isOnMaxRewards (tokenId: number) {
    const rewards = this.rewardsInfos[tokenId]
    return rewards ? rewards.isOnMaxRewards : false
  }

  async mounted () {
    console.warn('dialogMounted')
  }

  async initMissionInfo () {
    this.loadingMissionInfo = true
    const config = this.getConfig()
    const res = await getMissionData(config, this.currentBlock, config.trainingRoomAddress)
    this.missionData = res.missionData
    this.missionList = res.missionList
    this.loadingMissionInfo = false
  }

  initSelected () {
    console.warn('init selected')
    this.selectedModes = {}
    this.selectedMission = {}
    
    this.boxParamsProp.forEach(item => {
      const tokenId = item.metadata?.token_id
      if (tokenId != null) {
        const currentMode = this.trooperzModes[tokenId] ? this.trooperzModes[tokenId].mode : ''
        this.$set(this.selectedModes, tokenId, currentMode)
        const currentMissionId = currentMode === 'mission' &&  this.trooperzModes[tokenId].missionId ? this.trooperzModes[tokenId].missionId : null
        this.$set(this.selectedMission, tokenId, currentMissionId)
      }
    })
  }

  getConfig () {
    const config = this.$config
    if (!config) {
      throw Error('Can not find config.')
    }
    return config
  }

  checkSubmit () {
    for (let key in this.selectedModes) {
      if (!this.isModeUpdated(Number(key))) {
          console.warn('Trooperz mode not updated', key)
          return false
      }
    }
    return true
  }

  async askSubmit () {
    if (!this.checkSubmit()) {
      this.openWarning(this.contentSomeTrooperzNotUpdated, this.submitBatch.bind(this), null, 'Send batch', 'Continue editing')
    } else {
      this.submitBatch()
    }
  }

  getTrooperzPagePath (tokenId: string | number) {
    return this.$router.resolve({ name: 'Trooperz', params: { tokenId: tokenId.toString() } })
  }

  async submitBatch () {
    this.loadingTransaction = true
    this.activeDialogTransaction = true
    this.success = false
    this.opeHash = null
    const config = this.$config
    let error = false

    if (!config) {
      throw Error('Can not find config.')
    }
    const batchParams: BatchParams[] = this.boxParamsProp.filter((item) => {
      // filter those with no mode. 
      const tokenId = Number(item.metadata?.token_id)
      return !!this.selectedModes[tokenId]
    }) .map(item => {
      const tokenId = Number(item.metadata?.token_id)
      let selectedMode = this.selectedModes[tokenId]
      let missionId = null

      if (selectedMode === 'mission') {
        missionId = this.selectedMission[tokenId]
      }

      return {
        mode: selectedMode as MissionMode,
        trooperzId: tokenId,
        missionId: missionId ? missionId : null
      }
    })
    try {
        const trainingRoomAddress = config.trainingRoomAddress
        const batchOp = await setTrooperzModeBatchOneTransaction(this.$tezos.wallet, batchParams, trainingRoomAddress)
        console.log('Operation hash:', batchOp.opHash);
        this.opeHash = batchOp.opHash
        const result = await batchOp.confirmation();
    } catch (err) {
        this.activeDialogTransaction = false
        error = true
        console.warn("Got error:", err);
    }
    this.success = !error
    this.loadingTransaction = false
  }

  emitClose (success: boolean) {
    this.$emit('close', success)
  }

  removeFromBatch (trooperzId: number) {
    this.$delete(this.selectedModes, trooperzId)
    this.$delete(this.selectedMission, trooperzId)
    this.$delete(this.selectedMissionName, trooperzId)
    this.$emit('removeFromBatch', trooperzId)
  }

  onChangeMode (tokenId: number) {
    console.warn('change mode')

  }

  getCurrentMode (tokenId: string | number) {
    if (this.trooperzModes[Number(tokenId)]) {
      return this.trooperzModes[Number(tokenId)].mode
    }
    return null
  }

  getModeInfo (tokenId: string | number) {
    const currentMode = this.getCurrentMode(tokenId)
    return currentMode ? currentMode : 'Not actif'
  }

  onChangeSelectMode (event: any, boxParam: TrooperzBoxParams) {
    const tokenId = boxParam.metadata?.token_id
    if (tokenId && event === 'mission' && boxParam.upgradablesAttributes && boxParam.metadata?.attributes) {
      this.openMissionDialog(tokenId)
    } else {
      if (['rest', 'train'].includes(event) && this.selectedMission[Number(tokenId)]) {
        this.selectedMission[Number(tokenId)] = null
        this.selectedMissionName[Number(tokenId)] = null
      }
    }
  }

  // Mission set all 

  askSetMissionAll (missionKey: string) {
    if (!this.checkMissionAll(missionKey)) {
      this.openWarning(this.contentSomeTrooperzFailRequirements, () => {
        this.setMissionIdAll(missionKey)
      },
      null,
      'Ok, I understand!',
      'Choose another mission.')
    } else {
      this.setMissionIdAll(missionKey)
    }
  }

  checkMissionAll(missionKey: string) {
    const missionConfig = this.getMissionConfig(missionKey)

    for (let key in this.selectedModes) {
      if (!this.checkMissionRequirementsTrooperz(Number(key), missionConfig)) {
        console.warn('[checkMissionAll] Mission requirements not check.', key)
        return false
      }
    } 
    return true
  }

  setMissionIdAll (missionKey: string) {
    const missionConfig = this.getMissionConfig(missionKey)

    for (let key in this.selectedModes) {
      if (!this.checkMissionRequirementsTrooperz(Number(key), missionConfig)) {
        console.warn('[setMissionIdAll] Mission requirements not check.')
        continue
      }
      this.setSelectedModes(key, 'mission', missionKey)
    } 
    this.dialogChooseMissionAll = false
  }

  setSelectedModes (trooperzId: number | string, mode: MissionMode, missionKey?: number | string) {
    if (mode === 'mission') {
      if (!missionKey) {
        console.warn('No mission key provided on mission update')
      }
      this.$set(this.selectedModes, trooperzId, mode)
      this.$set(this.selectedMission, trooperzId, Number(missionKey))
      this.$set(this.selectedMissionName, trooperzId, this.getTitleMission(Number(missionKey)))
    } else {
      this.$set(this.selectedModes, trooperzId, mode)
      this.$set(this.selectedMission, trooperzId, null)
      this.$set(this.selectedMissionName, trooperzId, null)
    }
  }

  onCloseMissionDialogAll () {
    this.dialogChooseMissionAll = false
  }

  // Choose mission managements

  setMissionId (missionKey: string, trooperzId: number | null) {
    if (!trooperzId) {
      console.warn('Not a valid trooperz id.')
      return
    }
    this.selectedMission[trooperzId] = Number(missionKey)
    this.selectedMissionName[trooperzId] = this.getTitleMission(Number(missionKey))
    this.closeMissionDialog()
  }


  closeMissionDialog () {
    this.dialogChooseMission = false
    this.currentTrooperzIdMissionDialog = null
    this.currentAttributesMissionDialog = null
    this.currentTrooperzXpMissionDialog = null
  }

  openMissionDialog (tokenId: number) {
    console.warn('open mission dialog')
    const boxParam = this.boxParamsProp.find(item => item.metadata?.token_id === tokenId)
    if (!boxParam) {
      console.warn('can not find box params.')
      return
    }
    this.currentTrooperzIdMissionDialog = tokenId
    this.currentAttributesMissionDialog = this.boxParamsToTrooperzAttributes(boxParam)
    this.currentTrooperzXpMissionDialog = this.trooperzXp[tokenId]
    this.dialogChooseMission = true
  }

  onCloseMissionDialog () {
    if (!this.currentTrooperzIdMissionDialog) {
      console.warn('No trooperz id in current dialog !')
      return
    }
    this.setSelectedModes(this.currentTrooperzIdMissionDialog, this.trooperzModes[this.currentTrooperzIdMissionDialog].mode)
    this.closeMissionDialog()
  }

  //

  onChangeSelectModeAll (mode: MissionMode) {
    console.warn(mode)
    if (!mode) {
      console.warn('Not valid mode')
      return
    }

    if (mode === 'mission') {
      this.dialogChooseMissionAll = true
    } else {
      // train, energy
      for (let key in this.selectedModes) {
        this.setSelectedModes(key, mode)
      } 
    }
    setTimeout(() => {
      this.dialogSetAll = false
      this.selectedModeAll = ''
    }, 500)
  }

  boxParamsToTrooperzAttributes (boxParam: TrooperzBoxParams): TrooperzAttributes | null {
    if (boxParam.upgradablesAttributes && boxParam.metadata?.attributes) {
      const family = this.getFamily(boxParam)
      const hasPet = this.getHasPet(boxParam)
      return this.getTrooperzAttributes(boxParam.upgradablesAttributes, family, hasPet)
    }
    return null
  }

  getTrooperzAttributes (mutableAttributes: MutableAttributes, family: Family, hasPet: boolean): TrooperzAttributes {
    return {
      mutable_attributes: mutableAttributes,
      family: family,
      has_pet: hasPet
    }
  }

  getFamily (boxParam: TrooperzBoxParams) {
    return boxParam.metadata ? getInAttributes('family', boxParam.metadata.attributes) : null
  }

  getHasPet (boxParam: TrooperzBoxParams) {
    return boxParam.metadata ? getInAttributes('has_pet', boxParam.metadata.attributes) : null
  }

  getTitleMission (missionKey: number) {
    let missionJson = this.jsonMissionDictFromMissionDialog[String(missionKey)]
    return missionJson ? missionJson.title : null
  }

  getTcoinRewards (trooperzId: number) {
    let config = this.getConfig()
    let xp = this.trooperzXp[trooperzId]
    let missionId = this.selectedMission[trooperzId]
    let tcoinByBlock = this.getTcoinBlockMission(Number(missionId))
    return roundValue(getRoiByHours(Number(tcoinByBlock), 
                        config.timeBlock, 
                        xp, 
                        config.nbDigitTcoin,
                        this.isUnique(trooperzId)), 8)
  }

  getEnergy (tokenId: number) {
    const energy = this.trooperzEnergy[tokenId]
    const rewards = this.rewardsInfos[tokenId]
    const currentMode = this.getCurrentMode(tokenId)
    return getEnergyWithRewards(energy, rewards, currentMode, this.maxEnergy)
  }

  getEnergyInfo (tokenId: number) {
    const energy = this.trooperzEnergy[tokenId]
    const rewards = this.rewardsInfos[tokenId]
    const currentMode = this.getCurrentMode(tokenId)
    return getEnergyInfoWithRewards(energy, rewards, currentMode)
  }

  getEnergyRewards () {
    const config = this.getConfig()
    if (!this.trainingConfig) {
      console.warn('No training config!')
      return null
    }
    return convertByBlockToByHours(this.trainingConfig.energy_by_block, config.timeBlock)
  }

  getXpRewards () {
    const config = this.getConfig()
    if (!this.trainingConfig) {
      console.warn('No training config!')
      return null
    }
    return convertByBlockToByHours(this.trainingConfig.xp_by_block, config.timeBlock)
  }

  getEnergyInfoRewards () {
    const rewards = this.getEnergyRewards()
    return `${rewards} energy / hours`
  }

  getXpInfoRewards () {
    const rewards = this.getXpRewards()
    return `${rewards} xp / hours`
  }

  getTrooperzRewards (trooperzId: number) {
    const selectedMode = this.selectedModes[trooperzId]
    if (!selectedMode) {
      return null
    }

    if (selectedMode === 'mission') {
      const selectedMission = this.selectedMission[trooperzId]
      if (!selectedMission) {
        return null
      }
      return this.getTcoinRewards(trooperzId)
    } else if (selectedMode === 'train') {
      return this.getXpRewards()
    } else if (selectedMode === 'rest') {
      return this.getEnergyRewards()
    }
    console.warn('Can not find rewards!')
    return null
  }

  getTrooperzInfoRewards (trooperzId: number) {
    const selectedMode = this.selectedModes[trooperzId]
    if (!selectedMode) {
      return null
    }

    if (selectedMode === 'mission') {
      const selectedMission = this.selectedMission[trooperzId]
      if (!selectedMission) {
        return null
      }
      return this.getInfoRewardsTcoin(trooperzId)
    } else if (selectedMode === 'train') {
      return this.getXpInfoRewards()
    } else if (selectedMode === 'rest') {
      return this.getEnergyInfoRewards()
    }
    console.warn('Can not find rewards!')
    return null
  }

  displayRewards (trooperzId: number): boolean {
    const selectedMode = this.selectedModes[trooperzId]
    if (!selectedMode || !this.isModeUpdated(trooperzId)) {
      return false
    }
    
    if (selectedMode === 'mission') {
      return !!this.selectedMission[trooperzId]
    }
    return true
  }


  getColorRewards(trooperzId: number) {
    const rewardsType = this.getRewardsTypeForTrooperz(trooperzId)
    return this.getColorRewardsType(rewardsType)
  }

  getColorRewardsCurrentMode(trooperzId: number) {
    const rewardsType = this.getRewardsTypeForTrooperzCurrentMode(trooperzId)
    return this.getColorRewardsType(rewardsType)
  }


  getColorRewardsType (rewardsType: RewardsType | null) {
    if (rewardsType === 'tcoin') {
        return '#E6A529'
    } else if (rewardsType === 'energy') {
        return '#B429E6'
    } else if (rewardsType === 'xp') {
        return 'aqua'
    }
    return null
  }

  getInfoRewardsTcoin(trooperzId: number) {
      let config = this.getConfig()
      let xp = this.trooperzXp[trooperzId]
      let missionId = this.selectedMission[trooperzId]
      let tcoinByBlock = this.getTcoinBlockMission(Number(missionId))
      return getInforRewardsTcoin(Number(tcoinByBlock), xp, config.nbDigitTcoin, this.isUnique(trooperzId))
  }

  getTcoinBlockMission(missionId: number) {
    let mission =  this.getMissionConfig(missionId)
    return mission?.tcoin_by_block
  }

  getMissionOfTrooperz (trooperzId: number) {
    const missionId = this.selectedMission[trooperzId]
    if (missionId) {
      let mission =  this.getMissionConfig(missionId)
      return mission
    }
    return null
  }

  getMissionConfig (missionId: string | number) {
    return this.missionList.find(item => item.key === String(missionId))
  }

  getTotalRewards () {
    let total = 0
    for (let key in this.selectedMission) {
      if (this.selectedMission[key] && this.hasEnougthEnergyToEnterMission(key)) {
        total += this.getTcoinRewards(Number(key))
      }
    }
    return roundValue(total, 4)
  }

  hasEnougthEnergyToEnterMission (tokenId: number | string) {
    return this.trainingConfig && this.getEnergy(Number(tokenId)) > this.trainingConfig.mission_energy_cost_by_block
  }

  getTotalToHarvest () {
    let total = 0
    for (let key in this.rewardsInfos) {
      const currentMode = this.getCurrentMode(key)
      if (this.isInBatch(Number(key)) && this.rewardsInfos[key] && currentMode && currentMode === 'mission') {
        total += this.rewardsInfos[key].rewards
      }
    }
    return roundValue(total, 4)
  }

  getRewardsTypeForTrooperz (trooperzId: number) {
    const selectedMode = this.selectedModes[trooperzId]
    return selectedMode ? getRewardsForMode(selectedMode) : null
  }

  getRewardsTypeForTrooperzCurrentMode (trooperzId: number) {
    const selectedMode = this.trooperzModes[trooperzId]
    return selectedMode ? getRewardsForMode(selectedMode.mode) : null
  }

  isModeUpdated (trooperzId: number) {
    const selectedMode = this.selectedModes[trooperzId]
    if (!selectedMode) {
      return false
    } else if (!this.trooperzModes[trooperzId]) {
      return true // selectedMode is True and no previous mode
    } else if (selectedMode === 'mission') {
      const selectedMission = this.selectedMission[trooperzId]
      return selectedMission && selectedMission !== this.trooperzModes[trooperzId].missionId
    }

    return selectedMode !== this.trooperzModes[trooperzId].mode
  }

  isUnique (trooperzId: number) {
      const config = this.$config
      return config && config.uniqueTrooperz.includes(Number(trooperzId))
  }

  openSetAllTo () {
    this.dialogSetAll = true
  }

  closeSelectAll () {
    this.dialogSetAll = false
  }

  /**
   * Will check if a trooperz fulfill the requirements for his mission. 
   * If a missionConfig is provided, will check versus the provided mission in place of check in selectedMission
   * @param trooperzId 
   * @param missionConfig 
   */
  checkMissionRequirementsTrooperz (trooperzId: number, missionConfig?: WithKey<MissionConfig> | null) {
    const lvl = this.getLevel(this.trooperzXp[trooperzId])
    const boxParams = this.boxParamsProp.find(item => Number(item.token_id) === trooperzId)
    if (boxParams) {
      const trooperzAttributes = this.boxParamsToTrooperzAttributes(boxParams)

      if (!missionConfig) {
        const res = this.getMissionOfTrooperz(trooperzId)
        missionConfig = res ? res : null
      }

      if (!trooperzAttributes || !missionConfig) {
        console.warn('Not enougth data for check mission requirements')
        return false
      }
      return checkMissionRequirements(lvl, trooperzAttributes, missionConfig)
    }
    console.warn('Can not find box params. Check requirements.')
    return false
  }

  getTextMaxRewards (tokenId: number) {
      const currentMode = this.getCurrentMode(tokenId)
      if (currentMode === 'rest') {
          return textMaxRewardsEnergy
      }
      return textMaxRewardsOther
  }

  isInBatch (tokenId: number) {
    return this.idsInBatch.includes(tokenId)
  }

  get isLittleScreen () {
    return this.isMobile
  }

  get idsInBatch () {
    return this.boxParamsProp.map(item => item.metadata?.token_id)
  }

  @Watch('batchDialogProp', {immediate: true})
  async onChangeBatchDialogProp() {
    if (this.batchDialogProp) {
      this.dialogIsLoading = true
      this.initSelected()
      await this.initMissionInfo()
      this.dialogIsLoading = false
    }
  }
}
