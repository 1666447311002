



























import { Component, Prop, Vue, PropSync } from 'vue-property-decorator';
import { TrooperzMetadata, MutableAttributes, TrooperzBoxParams, MissionMode, ArtBoxParams } from '@/type';

@Component({
    components: {}
})
export default class BatchBtnContainer extends Vue {
  @PropSync('batchOn', {type: Boolean})
  batchOnProp!: boolean

  @PropSync('boxParams')
  boxParamsProp!: TrooperzBoxParams[]

  @Prop({ required: true })
  allTrooperz!: TrooperzBoxParams[]

  @Prop({default: false})
  loading!: boolean

  @Prop({default: false})
  disabled!: boolean

  alertContentBatchOn = `
  Click on a Trooperz to add it to those selected for the batch. \n
  Click on a selected Trooperz to remove it. \n
  Click on "Select all" to select all those of the current search. \n
  Once at least one Trooperz has been selected, click on "Set mode batch" to select the modes of your Trooperz.
  `

  clickOnBatch () {
      this.batchOnProp = !this.batchOnProp
      this.$nextTick(() => {
        if (!this.batchOnProp) {
            console.warn('reset box params')
            this.boxParamsProp = []
        }
      })
  }

  clickOnSetMode () {
      this.$emit('click-setmode')
  }

  clickOnSelectAll () {
      if (this.areAllSelected) {
        this.$emit('click-unselectall')
      } else {  
        this.$emit('click-selectall')
      }
  }
  /**
   * return true if all the trooperz in the current batch are in the current search
   */
  get areAllSelected () {
    if (!this.boxParamsProp || !this.allTrooperz) {
      return null
    }
    const selectedIds = this.boxParamsProp.map(item => item.token_id)
    const allTrooperzIds = this.allTrooperz.map(item => item.token_id)
    return this.boxParamsProp.length && selectedIds.every(item => {
      return allTrooperzIds.includes(item)
    })
  }

  get labelSetMode () {
    return 'Set mode batch'.toUpperCase()
  }

  get labelSelectAll () {
    const toReturn = !this.areAllSelected ? 'Select all' : 'Unselected all'
    return toReturn.toUpperCase()
  }

  get labelBatch () {
      return !this.batchOnProp ? 'Active batch mode'.toUpperCase() : 'Cancel batch'.toUpperCase()
  }

  get areTrooperzSelected () {
      return this.boxParamsProp.length && this.batchOnProp
  }

  get alertContent () {
    if (this.batchOnProp) {
      return this.alertContentBatchOn
    }
    return ''
  }
}
