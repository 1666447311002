



















import { Component, Vue, PropSync, Prop } from 'vue-property-decorator';
import TrooperzBox from '@/components/TrooperzBox.vue'
import { Family} from '@/type';

@Component({
  components: {
      TrooperzBox
  },
})
export default class RarityCheckerComp extends Vue {
    allFamily: Family[] = ['aki', 'shibu', 'kabu', 'juku']

    @PropSync('family')
    familyProp!: Family | null

    @Prop()
    height!: string


    toggleFamily (family: Family) {
        if (this.familyProp === family) {
            this.familyProp = null
        } else {
            this.familyProp = family
        }
    }

    getStyle () {
        return this.height ? `height: ${this.height}` : null
    }

}
