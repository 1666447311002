
























import { Component, Vue, Prop, Watch, PropSync } from 'vue-property-decorator';
import TrooperzBox from '@/components/TrooperzBox.vue'
import { MissionMode, TrooperzApiResult, TrooperzBoxParams } from '@/type';


@Component({
  components: {
      TrooperzBox
  },
})
export default class ModeSelect extends Vue {
    allMode: MissionMode[] = ['mission', 'train', 'rest']

    @PropSync('mode')
    modeProp!: (MissionMode | 'no mode') | null


    toggleMode (mode: (MissionMode | 'no mode') ) {
        if (this.modeProp === mode) {
            this.modeProp = null
        } else {
            this.modeProp = mode
        }
    }

}
