


































import { Component, Prop, Vue, PropSync, VModel } from 'vue-property-decorator';
import { TrooperzMetadata, MutableAttributes, TrooperzBoxParams, MissionMode, ArtBoxParams } from '@/type';

@Component({
    components: {}
})
export default class BatchSelectAll extends Vue {
  @VModel()
  dialogSetAll!: boolean

  @PropSync('selectedModeAll')
  selectedModeAllProps!: MissionMode | ''

  @Prop()
  modeOptions!: {label: string, value: MissionMode}[]

  onChangeSelectModeAll (mode: MissionMode) {
      this.$emit('changeSelectAll', mode)
  }

  closeSelectAll () {
      this.$emit('closeSelectAll')
  }

}
