











































import { Component, Prop, Vue, PropSync } from 'vue-property-decorator';
import { getIpfsPath, getReqForLvl, getLvlWithXp, getTzktDomain, subAddress, getExlorerUrl } from '../utils/utilities'
import { TrooperzMetadata, MutableAttributes, TrooperzBoxParams, MissionMode, ArtBoxParams } from '@/type';
import { mixins } from 'vue-class-component';
import HasRank from '../mixins/HasRank';
import HasXp from '../mixins/HasXp';
import StatsBarFull from '@/components/StatsBarFull.vue'

@Component({
    components: {
        StatsBarFull
    }
})
export default class ArtBox extends mixins(HasRank, HasXp) {
    @Prop()
    data!: ArtBoxParams

    clickOnImg () {
        this.$emit('clickOnImg')
    }

    subText (str: string) {
        return subAddress(str)
    }

    getIpfsPath (ipfs: string) {
        return getIpfsPath(ipfs)
    }

    next () {
        this.$emit('next')
    }

    getExplorerUrl () {
        if (this.$config && this.data && this.data.contract) {
            return getExlorerUrl(this.$config?.networkTzkt, this.data.contract, this.data.token_id)
        }
        return ''
    }
}
