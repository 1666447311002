









































































































































import WalletMixin from '@/mixins/WalletMixin';
import TrooperzData from '@/mixins/TrooperzData';
import HasNotification from '@/mixins/HasNotification';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { getTrooperzMetadata } from '../contractCaller/contractCaller'
import { getInAttributes, getIpfsPath, getLvlWithXp, roundValue } from '../utils/utilities'
import { mixins } from 'vue-class-component';
import { TrooperzMetadata, TrooperzApiResult, MutableAttributes, TrooperzBoxParams, 
    CrowdsaleContractStorage, Family, TrooperzInfo, MissionMode, ModeInfo, TrainingConfig, MissionConfig, WithKey, 
    TrooperzInfoMission, TokenContractStorage, TokenContractStorageNumber, RewardsInfo, StatsFilter, TbotFilterMode, DeviceType } from '@/type';
import TrooperzBox from '@/components/TrooperzBox.vue'
import FamilySelect from '@/components/FamilySelect.vue'
import ModeSelect from '@/components/ModeSelect.vue'
import BuyBtn from '@/components/BuyBtn.vue'
import TBotFilter from '@/components/TBotFilter.vue'
import PatchNote from '@/components/PatchNote.vue'
import BatchBtnContainer from '@/components/Batch/BatchBtnContainer.vue'
import BatchCard from '@/components/Batch/BatchCard.vue'
import StatsFilterComponent from '@/components/Filter/StatsFilterComponent.vue'
import RewardsIcon from './RewardsIcon.vue';
import { UAParser } from 'ua-parser-js'
import TcoinInfoBox from './Common/TcoinInfoBox.vue'

@Component({
    components: {
        TrooperzBox,
        FamilySelect,
        ModeSelect,
        BuyBtn,
        BatchBtnContainer,
        BatchCard,
        TBotFilter,
        RewardsIcon,
        PatchNote,
        StatsFilterComponent,
        TcoinInfoBox
    }
})
export default class YourTrooperz extends mixins(WalletMixin, HasNotification, TrooperzData) {
    selectedName: string[] = []
    selectedMode: (MissionMode | 'no mode') | null = null
    allFamily: Array<Family | 'all'> = [
        'all',
        'aki',
        'juku',
        'kabu',
        'shibu',
        'tcorp'
    ]

    missionList: {[key: number]: MissionConfig} = {}

    onlyOnMaxRewards = false

    batchOn = false
    batchDialog = false
    trooperzBatch: TrooperzBoxParams[] = []

    tbotMode: TbotFilterMode = 'no_mode'

    deviceType: DeviceType | null = null

    maxBatch = 70


    batchModeMobileText = `
    Batch mode is not optimized for small screen and mobile at the moment. Please use a desktop or laptop to fully enjoy the experience.
    `
    
    statsFilter: StatsFilter = {
        hacking: -1,
        fire_arms: -1,
        trickery: -1,
        close_combat: -1,
        lvl: -1
    }

    async mounted () {
        await this.initPage()
        this.initDeviceType()
    }

    initDeviceType () {
        const ua = window.navigator.userAgent
        const parser = new UAParser(ua)
        this.deviceType = parser.getDevice().type as DeviceType
    }

    get isMobile () {
        return this.deviceType === 'mobile'
    }

    /**START Batch method */

    openBatchDialog () {
        this.batchDialog = true
    }

    closeBatch (success: boolean) {
        console.warn('success : ' + success)
        this.batchDialog = false
        this.batchOn = false
        this.trooperzBatch = []
        this.initPage()
    }

    toggleBatch (boxParams: TrooperzBoxParams) {
        if (!this.batchOn) {
            return
        }


        if (this.isTrooperzInBatch(boxParams.token_id)) {
            this.removeFromBatch(boxParams)
        } else {
            this.addToBatch(boxParams)
        }
    }

    notifIsBatchFull () {
        console.warn('Batch is full!')
        this.openNotification(this.textBatchIsFull ,'Batch is full')
    }


    addToBatch (boxParams: TrooperzBoxParams) {
        if(this.isBatchFull) {
            this.notifIsBatchFull()
            return
        }
        console.warn("Add to batch")
        this.trooperzBatch.push(boxParams)
    }

    removeFromBatch (boxParams: TrooperzBoxParams) {
        this.removeFromBatchWithTokenId(boxParams.token_id)
    }

    removeFromBatchWithTokenId (tokenId: string | number) {
        console.warn("Remove from batch")
        const itemToRemove = this.trooperzBatch.find(item => Number(item.token_id) === Number(tokenId))
        if (itemToRemove) {
            const index = this.trooperzBatch.indexOf(itemToRemove);
            if (index > -1) { 
                this.trooperzBatch.splice(index, 1)
            }
        } else {
            console.warn('Can not find item to remove : ' + tokenId)
        }
    }

    isTrooperzInBatch(trooperzId: number | string) {
        const idInBatch: number[] = this.trooperzBatch.map(item => Number(item.token_id))
        return idInBatch.includes(Number(trooperzId))
    }

    selectAll () {
        if(this.isBatchFull) {
            this.notifIsBatchFull()
            return
        }
        const idsInBatch = this.trooperzBatch.map(item => item.token_id)
        let toAdd = this.ct.filter(item => !idsInBatch.includes(item.token_id))
        const lenToAdd = toAdd.length
        const lenInBatch = idsInBatch.length
        const futurTotal = lenInBatch + lenToAdd
        if (futurTotal >= this.maxBatch) {
            let canBeAdd = this.maxBatch - lenInBatch
            toAdd = toAdd.slice(0, canBeAdd)
            console.warn('Futur total too high!')
            const text = `${this.textBatchIsFull} There are already ${lenInBatch} Trooperz in the batch, so only the first ${canBeAdd} 
            have been added to the batch so as not to exceed the limit of ${this.maxBatch}.` 
            this.openNotification(text, 'Batch will be full!')
        }
        this.trooperzBatch.push(...toAdd)
    }

    unselectAll () {
        this.trooperzBatch = []
    }

    /**END Batch method */

    get allName (): {full: string, occi: string}[] {
        const allName = this.data.map(item => item.metadata?.name).filter(item => typeof item === 'string') as string[]
        const allNameUnique = [...new Set(allName)]
        return allNameUnique.map(item => {
            return {
                full: item,
                occi: item !== 'Trooperz To Reveal' ? item?.split('(')[1].split(')')[0] : 'Trooperz To Reveal'
            }
        })
    }

    get ct () {
        return this.data.filter(item => {
            const isRevealed = (_item: TrooperzBoxParams) => _item && _item.metadata && !_item.metadata.name.includes('Trooperz To Reveal')

            if (!item.metadata?.artifactUri) {
                return false
            }
            if (this.selectedName && this.selectedName.length) {    
                if (!this.selectedName.some(sel => item.metadata?.name && item.metadata.name.includes(sel))) {
                    return false
                }  
            }
            if (this.selectedMode && this.selectedMode.length) {
                if (this.selectedMode === 'no mode') {
                    if (this.trooperzModes[Number(item.token_id)]) {
                        return false
                    }
                } else {
                    if (!this.trooperzModes[Number(item.token_id)] || this.selectedMode !== this.trooperzModes[Number(item.token_id)].mode) {
                        return false
                    }  
                }
            }

            if (this.onlyOnMaxRewards) {
                if (!this.rewardsInfos[Number(item.token_id)] || !this.rewardsInfos[Number(item.token_id)].isOnMaxRewards) {
                    return false
                }
            }

            if (this.batchOn) {
                if (!isRevealed(item)) {
                    return false
                }
            }

            if (this.tbotMode && this.tbotMode !== 'no_mode') {
                const hasPet = getInAttributes('has_pet', item.metadata.attributes)
                if (!isRevealed(item) || (this.tbotMode === 'with_tbot' && !hasPet) || (this.tbotMode === 'without_tbot' && hasPet)) {
                    return false
                }
            }

            for (let stats in this.statsFilter) {
                const filter = this.statsFilter[stats as keyof StatsFilter]
                if (filter && filter > 0) {
                    if (!isRevealed(item)) {
                        return false
                    }
                    if (stats === 'lvl') {
                        const lvl = getLvlWithXp(this.trooperzXp[Number(item.token_id)])
                        if (lvl < filter) {
                            return false
                        }
                    } else if (item && item.upgradablesAttributes && item.upgradablesAttributes[stats as keyof MutableAttributes] < filter) {
                        return false
                    }
                    if (!isRevealed(item) || 
                    (item && item.upgradablesAttributes && item.upgradablesAttributes[stats as keyof MutableAttributes] < filter)) {
                        return false
                    }
                }
            }
            return true
        })
    }

    get textFilterMaxRewards () {
        if (this.onlyOnMaxRewards) {
            return 'Max rewards : Display only on max rewards'
        }
        return 'Max rewards : Display all'
    }

    get maxEnergy (): number | null {
        return this.infoTokenContract ? this.infoTokenContract.max_energy : null
    }

    get isBatchFull () {
        return this.trooperzBatch.length >= this.maxBatch
    }

    get textBatchIsFull () {
        return `
        Only a maximum of ${this.maxBatch} Trooperz can be sent in batch at the same time.
        `
    }

    get buyTrooperzPath () {
        if (this.$config) {
            return { name: 'Marketplace' , query: {contractSearch: this.$config.tokenContractAddress}}
        }
        return { name: 'Marketplace'}
    }

    getTrooperzModeArg (tokenId: number) {
        return this.trooperzModes[tokenId] && this.trooperzModes[tokenId].mode 
            ? this.trooperzModes[tokenId].mode 
            : null
    }


    getIsOnMaxRewardsArg (tokenId: number): boolean {
        return this.rewardsInfos && this.rewardsInfos[tokenId]
            ? this.rewardsInfos[tokenId].isOnMaxRewards 
            : false
    }

    getArgEnergy (tokenId: number): number | null {
        return this.trooperzEnergy && this.trooperzEnergy[tokenId] != null
            ? this.trooperzEnergy[tokenId] 
            : null
    }

    getArgRewards (tokenId: number): RewardsInfo | null {
        return this.rewardsInfos && this.rewardsInfos[tokenId]
            ? this.rewardsInfos[tokenId] 
            : null
    }



    getArgXp (tokenId: number): number | null {
        return this.trooperzXp && this.trooperzXp[tokenId] != null
            ? this.trooperzXp[tokenId] 
            : null
    }

    openLoading() {
        console.warn('open loading')
        this.$vs.loading({
            target: this.$refs['boxcircles'],
            type: 'circles'
        })
    }

    openLoadingTrooperz() {
        console.warn('open loading tr')
        for (let i in this.data) {
            let refKey = 'load_' + i

            if (this.$refs && this.$refs[refKey] && Array.isArray(this.$refs[refKey]) && (this.$refs[refKey] as Array<any>).length) {
                let ref = (this.$refs[refKey] as HTMLElement[])[0]
                this.$vs.loading({
                    target: ref,
                    type: 'circles'
                })
            }
        }

    }

    getTotalToHarvest () {
        let total = 0
        for (let key in this.rewardsInfos) {
            const currentMode = this.getTrooperzModeArg(Number(key))
            if (this.rewardsInfos[key] && currentMode && currentMode === 'mission') {
                total += this.rewardsInfos[key].rewards
            }
        }
        return roundValue(total, 4)
    }

    get displayPatchNote () {
        return false
    }

    @Watch('selectedFamily')
    onChangeSelectedFamily () {
        this.selectedName = []
        this.getTrooperz()
    }
}
