



































import { StatsFilter } from '@/type';
import { Component, Vue, VModel } from 'vue-property-decorator';
  
@Component({
    components: {}
})
export default class StatsFilterComponent extends Vue {
    @VModel()
    statsFilter!: StatsFilter

    itemsStatsFilter: {text: string, value: number}[] = []
    itemsStatsFilterLvl: {text: string, value: number}[] = []


    created () {
        this.itemsStatsFilter = this.generateStatsItem()
        this.itemsStatsFilterLvl = this.generateStatsItem(50)
    }

    getItemStats (key: keyof StatsFilter) {
        return key === 'lvl' ? this.itemsStatsFilterLvl : this.itemsStatsFilter
    }

    generateStatsItem (length = 10) {
        let items = [{
            text: 'no filter',
            value: -1
        }]
        const rangeArray = Array.from({length: length}, (_, i) => i + 1)
        for (let i of rangeArray) {
            items.push({
                text: `>=${i}`,
                value: Number(i)
            })
        }
        return items
    }
  }
  