












































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import WalletMixin from '@/mixins/WalletMixin';
import { mixins } from 'vue-class-component';
import { getTokenBalanceOfUser } from '@/apiCaller/tzktApi';
import { ArtBoxParams, NftMetadata } from '@/type';
import ArtBox from '@/components/ArtBox.vue'
import BuyBtn from '@/components/BuyBtn.vue'
import { getIpfsPath } from '../utils/utilities'

@Component({
    components: {
        ArtBox,
        BuyBtn
    }
})
export default class Comics extends mixins(WalletMixin) {
    comicsData: ArtBoxParams[] = []

    currentIndexFullscreen: number | null = null
    fullscreen = false

    async mounted () {
        await this.initPage()
    }

    async initPage () {
        if (this.address && this.$config) {
            const result = await getTokenBalanceOfUser(this.address, 
                                                                this.$config?.networkTzkt,   
                                                                this.$config?.comicsTokenAddress)

            this.comicsData = result.data.filter(item => item && item.token && item.token.metadata).map(
                item => {
                    return {
                        token_id: item.token.tokenId,
                        metadata: item.token.metadata,
                        balance: Number(item.balance),
                        contract: this.$config?.comicsTokenAddress
                    }
                })
            this.comicsData.sort((a, b) => Number(a.token_id) - Number(b.token_id))
        }

    }

    getIpfsPath (ipfs: string) {
        return getIpfsPath(ipfs)
    }

    openFullscreen (index: number) {
        this.currentIndexFullscreen = index
        this.fullscreen = true
    }

    openPreviousFullscreen () {
        if (this.currentIndexFullscreen == null) {
            return
        }
        if (this.currentIndexFullscreen === 0) {
            this.currentIndexFullscreen = this.comicsData.length - 1
        } else {
            this.currentIndexFullscreen -= 1
        }
    }

    openNextFullscreen () {
        if (this.currentIndexFullscreen == null) {
            return
        }
        if (this.currentIndexFullscreen === this.comicsData.length - 1) {
            this.currentIndexFullscreen = 0
        } else {
            this.currentIndexFullscreen += 1
        }
    }

    get buyComicsPath () {
        if (this.$config) {
            return { name: 'Marketplace' , query: {contractSearch: this.$config.comicsTokenAddress}}
        }
        return { name: 'Marketplace'}
    }

    get currentFullscreenImg () {
        if (this.currentIndexFullscreen == null) {
            return null
        }
        return this.comicsData[this.currentIndexFullscreen].metadata?.artifactUri
    }

    get address () {
        return this.$store.getters.getAddress
    } 

    set address (address: string | null) {
        this.$store.commit('setAddress', address)
    }
}
