



























import { Component, Vue, PropSync } from 'vue-property-decorator';
import { TbotFilterMode } from '@/type';

@Component({})
export default class TBotFilter extends Vue {
    @PropSync('tbot')
    tbotProp!: TbotFilterMode


    toggleMode (mode: TbotFilterMode) {
        if (this.tbotProp === 'with_tbot') {
            this.tbotProp = 'without_tbot'
        } else {
            this.tbotProp = 'with_tbot'
        }
    }

    disabledMode () {
        this.tbotProp = 'no_mode'
    }

    get labelFilterTbot () {
        return this.tbotProp === 'with_tbot' ? 'Filter Trooperz without Tbot' : 'Filter Trooperz with Tbot'
    }

}
