












import { Component, Prop, Vue } from 'vue-property-decorator';
import RewardsIcon from '@/components/RewardsIcon.vue';

@Component({
    components: {
        RewardsIcon
    }
})
export default class TcoinInfoBox extends Vue {
    @Prop({ default: 0})
    value!: number

    @Prop({ default: '7em'})
    width!: string

    @Prop({ default: '16px'})
    fontSize!: string

}
