























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { getIpfsPath, getReqForLvl, getLvlWithXp } from '../utils/utilities'
import { TrooperzMetadata, MutableAttributes, TrooperzBoxParams, MissionMode, RewardsInfo } from '@/type';
import { mixins } from 'vue-class-component';
import HasRank from '../mixins/HasRank';
import HasXp from '../mixins/HasXp';
import StatsBarFull from '@/components/StatsBarFull.vue'
import StatsTrooperzBox from '@/components/Common/StatsTrooperzBox.vue'
import { textMaxRewardsEnergy, textMaxRewardsOther } from '@/text/text'
import { getEnergyInfoWithRewards, getEnergyWithRewards } from '@/utils/rewardsHelper';

@Component({
    components: {
        StatsBarFull,
        StatsTrooperzBox
    }
})
export default class TrooperzBox extends mixins(HasRank, HasXp) {
    @Prop()
    data!: TrooperzBoxParams

    @Prop()
    clickable!: boolean

    @Prop()
    energy!: number

    @Prop()
    xp!: number

    @Prop()
    maxEnergy!: number

    @Prop()
    maxLvl!: number

    @Prop()
    mode!: MissionMode

    @Prop()
    isOnMaxRewards!: boolean

    @Prop()
    rewards!: RewardsInfo

    maxStats = 10

    clickOnImg () {
        let tokenId = this.data?.metadata?.token_id ? this.data.metadata.token_id : this.data.token_id
        if (this.clickable && this.data && this.data.metadata && tokenId) {
            this.$router.push({ name: 'Trooperz', params: { tokenId: tokenId.toString() } })
        }
    }

    getIpfsPath (ipfs: string) {
        return getIpfsPath(ipfs)
    }

    tokenIdIfNotReveal (metadata: TrooperzMetadata) {
        let revealed = metadata.attributes.find(item => item.name == 'revealed')
        // revealed == false , if undefined, the trooperz is revealed
        if (revealed !== undefined) {
            return `#${metadata.token_id}`
        }
        return ''
    }

    getEnergy () {
        return getEnergyWithRewards(this.energy, this.rewards, this.mode, this.maxEnergy)
    }

    getEnergyInfo () {
        return getEnergyInfoWithRewards(this.energy, this.rewards, this.mode)
    }

    get textMaxRewards () {
        if (this.mode === 'rest') {
            return textMaxRewardsEnergy
        }
        return textMaxRewardsOther
    }

    get stats () {
        return this.data && this.data.upgradablesAttributes ? this.data.upgradablesAttributes : null
    }

    get rank () {
        return this.data?.rank ? this.data?.rank : null
    }
}
